import colors from '../colors';

export interface ColorsTheme {
  colors: {
    abnNeutral50: string;
    abnNeutral300: string;
    abnGreen50: string;
    abnGreen100: string;
    abnGreen200: string;
    abnGreen300: string;
    abnGreen400: string;
    abnGreen500: string;
    abnGreen600: string;
    abnGreen670: string;
  };
}

export const defaultColorsTheme: ColorsTheme = {
  colors: {
    abnNeutral50: colors.abnNeutral50,
    abnNeutral300: colors.abnNeutral300,
    abnGreen50: colors.abnGreen50,
    abnGreen100: colors.abnGreen100,
    abnGreen200: colors.abnGreen200,
    abnGreen300: colors.abnGreen300,
    abnGreen400: colors.abnGreen400,
    abnGreen500: colors.abnGreen500,
    abnGreen600: colors.abnGreen600,
    abnGreen670: colors.abnGreen670,
  },
};

export const bisColorsTheme: ColorsTheme = {
  colors: {
    abnNeutral50: '#F3F3F3',
    abnNeutral300: colors.abnNeutral300,
    abnGreen50: colors.abnGreen50,
    abnGreen100: '#00A296',
    abnGreen200: colors.abnGreen500,
    abnGreen300: colors.abnGreen500,
    abnGreen400: colors.abnGreen500,
    abnGreen500: colors.abnGreen500,
    abnGreen600: colors.abnGreen600,
    abnGreen670: colors.abnGreen670,
  },
};
