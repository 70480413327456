const getInternalLink = (pageName, page, link = '', baseUrl = '') => {
  if (typeof page === 'string') {
    if (page.includes('mailto')) {
      return page;
    }
    return `${page}/`;
  }
  let newLink = link;
  if (!page && !link) {
    console.warn(`⚠️ No page found for '${pageName}'`);
    return '404/';
  }
  if (page.slug && page.slug !== '#') {
    newLink = link ? `${page.slug}/${link}` : page.slug;
  }
  if (page.parentPage) {
    return getInternalLink(pageName, page.parentPage, newLink, baseUrl);
  }

  return baseUrl ? `${baseUrl}/${newLink}/` : `${newLink}/`;
};

const parseRedirect = (redirect) => {
  if (
    (!redirect.from && !redirect.fromSlug) ||
    (!redirect.to && !redirect.toUrl)
  ) {
    console.warn(
      `Redirect model ${redirect.name} misses either a valid from or to field.`
    );
    return null;
  }

  const from = redirect.from
    ? `/${getInternalLink(redirect.pageName, redirect.from)}`
    : `/${redirect.fromSlug}`;
  const to = redirect.to
    ? `/${getInternalLink(redirect.pageName, redirect.to)}`
    : redirect.toUrl;

  return {
    from,
    to,
  };
};

const parseRedirects = (redirects) =>
  redirects.map((redirect) => parseRedirect(redirect)).filter(Boolean);

export { parseRedirects };
