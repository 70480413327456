export const PAGE_TYPES: { [key: string]: string } = {
  'about-page': 'article',
  'contact-form-page': 'contact page',
  'gated-contact-page': 'contact page',
  'home-page': 'homepage',
  'product-page': 'product page',
  contactPage: 'contact page',
  gatedContactPage: 'gated-contact-page',
  homePage: 'home page',
  article: 'article',
  formPage: 'form page',
  productPage: 'product-page',
  contentOverviewPage: 'content-overview-page',
  productOverviewPage: 'products-overview-page',
  wizardPage: 'wizard page',
  wizardResultPage: 'wizard-result-page',
  overviewPage: 'overview-page',
  pageNotFound: '404-page',
};

export const CARD_WRAPPER_TYPES = {
  anchorWrapper: 'anchor-wrapper',
  featuredArticlesWrapper: 'featured-articles-wrapper',
  faqWrapper: 'faq-wrapper',
  formWrapper: 'form-wrapper',
  gatedContentWrapper: 'gated-content-wrapper',
  ungatedContentWrapper: 'ungated-content-wrapper',
  stepHorizontalWrapper: 'steps-horizontal-wrapper',
  stepVerticalWrapper: 'steps-vertical-wrapper',
  previewCardWrapper: 'preview-card-wrapper',
  promovisualWrapper: 'promovisual-wrapper',
  quoteWrapper: 'quote-wrapper',
  storyCardsWrapper: 'story-cards-wrapper',
  linkCardsWrapper: 'link-cards-wrapper',
  uspWrapper: 'usp-wrapper',
  wizardResultsWrapper: 'wizard-results-wrapper',
  tileWrapper: 'tile-wrapper',
  productFeaturesWrapper: 'product-features-wrapper',
  feedbackCardWrapper: 'feedback-card-wrapper',
  iconListWrapper: 'icon-list-wrapper',
  linkTilesWrapper: 'link-tiles-wrapper',
};

export const CARD_TYPES = {
  anchor: 'anchor',
  disclaimer: 'disclaimer',
  featuredArticle: 'featuredArticle',
  faqItem: 'faq-item',
  header: 'header',
  highlight: 'highlight',
  imageOrVideoOnly: 'image-or-video-only',
  promovisual: 'promovisual',
  previewCard: 'preview-card',
  quote: 'quote',
  step: 'step',
  storyCard: 'story-card',
  linkCard: 'link-card',
  tile: 'tile',
  usp: 'usp',
  embeddedVideoCard: 'embedded-video-card',
  animatedVideoCard: 'animated-video-card',
  resultOptionCard: 'result-option-card',
  download: 'download',
  productFeature: 'product-feature',
  tip: 'tip',
  iconListItem: 'icon-list-item',
  linkTile: 'link-tile',
  generic: 'generic',
};

export const CONTENTFUL_INPUT_TYPES = {
  text: 'text',
  email: 'email',
  tel: 'tel',
  number: 'number',
  file: 'file',
  textarea: 'textarea',
  search: 'search',
  url: 'url',
  password: 'password',
  radio: 'radio',
  checkbox: 'checkbox',
};

export const FORM_CONTEXT_TYPES = {
  currency: 'currency',
  kvkNumber: 'kvk-number',
  specialCharacters: 'special-characters',
  postalCode: 'postal-code',
  accountNumber: 'account-number',
  numbersLettersOnly: 'numbers-letters-only',
};

export const CONTENTFUL_COMPONENT = {
  formInput: 'ContentfulFormInput',
  wizardCheckbox: 'ContentfulWizardComponentCheckbox',
  fieldset: 'ContentfulFieldset',
  pageProductOverview: 'ContentfulPageProductOverview',
  wizardOption: 'ContentfulWizardOption',
  wizardStep: 'ContentfulWizardStep',
  formContactForm: 'ContentfulFormContactForm',
  articlePage: 'ContentfulPageArticle',
  card: 'ContentfulCompCard',
  cardWrapper: 'ContentfulCompCardWrapper',
  tabWrapper: 'ContentfulTabWrapper',
};

export const CONTENTFUL_SLUGS = {
  home: '#',
};

export const CONTENTFUL_PAGE_NAME = {
  home: 'Home',
};

// eslint-disable-next-line no-shadow
export enum PageType {
  ABOUT = 'about-page',
  CONTACT_FORM = 'contact-form-page',
  GATED_CONTACT = 'gated-contact-page',
  HOME = 'home-page',
  PRODUCT = 'product-page',
  WIZARD_RESULT = 'wizard-result-page',
}

// eslint-disable-next-line no-shadow
export enum ContentfulLayout {
  DEFAULT = 'default',
  RESTRICTED = 'restricted',
  ISOLATED = 'isolated',
}
